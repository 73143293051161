$(document).ready(function () {
  var states = {};
  $('.js-datatable').DataTable({
      stateSave: true,
      stateDuration: -1,
      lengthMenu: [25, 50, 100, 200],
      pageLength: 50,
      retrieve: true,
      dom: 'rtipl',
      stateLoadParams: function (settings, data) {
        $.each(data.columns, function (index, value) {
          try {
            states[index] = JSON.parse(value.search.search);
            data.columns[index].search.search = '';
          }
          catch (e) {}
        })
      }
    }
  );

  $('.js-datatable .js-clear').on('click', function (e) {
    e.stopPropagation();
    const $th = $(this).parents('th');
    const index = $th.index();
    states[index] = {};
    $th.find('input').val('').trigger('change');
  });

  $('.js-datatable .js-clear-all').on('click', function (e) {
    const $table = $(this).parents('.js-datatable').DataTable();
    e.stopPropagation();
    $table.state.clear();
    states = {};
    $table.state().columns.forEach(function (value, index) {
      const $field = $($table.column(index).header()).find("[class*='js-table-search-']");
      $field.val('').trigger('change');
      // $($table.column(index).header()).find('input').val('').trigger('change');
    });
    $table.draw();
  });
  $('.js-table-search-text').each(function () {
    const $field = $(this);
    const index = $field.parents('th').index();
    const table = $field.parents('.js-datatable').DataTable();
    $field.on('click', function (e) {
      e.stopPropagation();
    });
    if ($field.is('select')) {
      table.column(index).data().unique().sort().each(function (value, index) {
        $field.append('<option value="' + value + '">' + value + '</option>');
      });
      if (table.state().columns[index].search.search) {
        $field.val(table.state().columns[index].search.search);
      }
    }
    $field.on('keyup change', function (e) {
      if ($field.val().length > 1 && table.column(index).search() !== $field.val()) {
        table.column(index).search($field.val()).draw();
      } else if ($field.val().length <= 1 && table.column(index).search().length > 0) {
        table.column(index).search('').draw();
      }
    })
  });

  $('.js-table-search-number').each(function () {
    const $input = $(this);
    const table = $input.parents('.js-datatable').DataTable();
    const kind = $input.data('kind');
    const index = $input.parents('th').index();
    $input.on('click', function (e) {e.stopPropagation();}).on(
      'change keyup', function () {table.column(index).draw();});
    $.fn.dataTable.ext.search.push(
      function (settings, data, dataIndex) {
        const number = parseInt($input.val(), 10);
        const value = parseFloat(data[index]) || 0;
        if (!states[index]) {
          states[index] = {};
        }
        if (isNaN(number)) {
          states[index][kind] = '';
          return true
        }
        states[index][kind] = number;
        if (kind === 'max') {
          return value <= number;
        }
        else if (kind === 'min') {
          return value >= number;
        }
        else {
          return true;
        }
      });
    table.on('stateSaveParams.dt', function(e, settings, data) {
      data.columns[index].search.search = JSON.stringify(states[index]);
    });
    if (states[index] && states[index][kind]) {
      $input.val(states[index][kind]);
      table.column(index).draw();
    }
  });

  $('.js-table-search-daterange').each(function () {
    const $input = $(this);
    const index = $input.parents('th').index();
    const table = $input.parents('.js-datatable').DataTable();
    $input.on('click', function(e){
      e.stopPropagation();
    });
    $.fn.dataTable.ext.search.push(
      function (settings, data, dataIndex) {
        if (!states[index]) {
          states[index] = {};
        }
        const value = parseInt($(table.cell(dataIndex, index).node()).data('order'));
        const min = parseInt(states[index].min);
        const max = parseInt(states[index].max);
        return isNaN(value) || isNaN(min) || isNaN(max) || ((min < value) && (value < max));
      }
    );
    table.on('stateSaveParams.dt', function (e, settings, data) {
      data.columns[index].search.search = JSON.stringify(states[index]);
    });
    $input.on('apply.daterangepicker', function (e, picker) {
      $input.val(picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY'));
      if (!states[index]) {
        states[index] = {};
      }
      states[index].min = picker.startDate ? picker.startDate.unix() : '';
      states[index].max= picker.endDate? picker.endDate.unix() : '';
      table.column(index).draw();
    });
    $input.on('keyup change', function (e) {
      if ($input.val().trim() === '') {
        states[index].min = states[index].max = null;
        table.column(index).draw();
        e.stopPropagation();
      }
    });
    if (states[index] && (states[index]['min'] || states[index]['max'])) {
      table.column(index).draw();
    }
  })
});
