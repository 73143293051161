$ = window.$ = window.jQuery = jQuery = require('jquery');
require("expose-loader?moment!moment");
require('popper.js');
require('bootstrap');
require("jquery-ui/ui/core");
require("jquery-ui/ui/widget");
require("jquery-ui/ui/position");
require("jquery-ui/ui/widgets/autocomplete");
require("jquery-ui/themes/base/all.css");
require('startbootstrap-sb-admin-2/js/sb-admin-2');
require('datatables.net');
require('datatables.net-bs4');
require('daterangepicker');
require('bootstrap4-datetimepicker');
require('./js/datatable_custom_filtering');

$(document).ready(function () {
  $('.dateinput').datetimepicker({
    format: 'D.MM.YYYY',
    locale: 'ru',
    showTodayButton: true
  });
  $('.datetimeinput').datetimepicker({
    format: 'D.MM.YYYY HH:mm',
    locale: 'ru',
    showTodayButton: true
  });
  $('.daterangeinput').daterangepicker({
    autoUpdateInput: false,
    autoApply: true,
    ranges: {
      'Сегодня': [moment(), moment()],
      'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'За последние 7 дней': [moment().subtract(6, 'days'), moment()],
      'Текущий месяц': [moment().startOf('month'), moment().endOf('month')],
      'Предыдущий месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    locale: {
      "format": "DD.MM.YYYY",
      "separator": " - ",
      "applyLabel": "Выбрать",
      "cancelLabel": "Отмена",
      "fromLabel": "From",
      "toLabel": "To",
      "customRangeLabel": "Выбрать",
      "weekLabel": "W",
      "daysOfWeek": [
        "Su",
        "Mo",
        "Tu",
        "We",
        "Th",
        "Fr",
        "Sa"
      ],
      "monthNames": [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ],
      "firstDay": 1
    },
  });
});

